import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { LocaleService } from 'src/app/services/locale.service';
import { Locale } from 'src/app/types';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public locale: Locale | null = null;
  private _unsubscribeAll: Subject<any> = new Subject();
  blogData: any;
  blogId: number | null = null;

  constructor(
    private apiService: ApiService,
    private localeService: LocaleService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

    // Listen for locale changes
    this.localeService.locale$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((locale: Locale) => {
        this.locale = locale;
        if (this.blogId) {
          this.fetchBlogData(); // Fetch blog data whenever locale changes
        }
      });

    // Get the blogId from route parameters
    this.route.params.subscribe((params) => {
      this.blogId = params['id'] ? +params['id'] : null;

      if (this.locale && params) {
        this.fetchBlogData();
      }
    });
  }

  fetchBlogData(): void {
    if (this.locale && this.blogId !== null) {
  
      this.apiService.getBlogById(this.blogId).subscribe(
        (res: any) => {
          if (res?.message === 'success' && res?.data) {
            this.blogData = res.data;
          } else {
            this.blogData = null;
          }
        },
        (error) => {
          this.blogData = null;
        }
      );
    } else {
      this.blogData = null;
    }
  }
  

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}