import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Category, Locale } from 'src/app/types';

@Component({
	selector: 'app-category-card',
	templateUrl: './category-card.component.html',
	styleUrls: ['./category-card.component.scss'],
})
export class CategoryCardComponent implements OnInit {
	@Input() category: Category;
	@Input() locale: Locale | null = null;
  
	imageUrl: string | null = null;
	isLoading: boolean = true;
  
	constructor() {}
  
	ngOnInit(): void {
	  this.imageUrl = this.category.image ? this.category.image : null;
	}
  
	onImageLoad(): void {
	  this.isLoading = false;
	}
  
	onImageError(event: Event): void {
	  const element = event.target as HTMLImageElement;
	  element.src = '../../../assets/podcasti/images/logooo.png'; // Fallback image path
	  this.isLoading = false;
	}
  }