<section class=""  dir="rtl" >
                <div class="row  row-fixed" >
                 
                   
                    <div class="col-3" >
                        <div class="item" >
                            <!-- <span class="player-counter">{{ currentTimeInSeconds }}</span> -->
                          
                            <!-- <span class="player-counter">{{ duration }}</span> -->
                        </div>
                    </div>
                    <div class=" col-3 "  >
                        <div class class="item"style=" " >
                               <a href="javascript:;" class="player-play-icon ss" *ngIf="!isPlaying" (click)="toggleState()">
                            <i class="fas fa-play" style="width: 40px;"></i>
                        </a>
                        <a href="javascript:;" class="player-play-icon" *ngIf="isPlaying" (click)="toggleState()">
                            <i class="fas fa-pause" style="width: 40px;"></i>
                        </a>
                        </div>
                    </div>
                </div>
</section>