import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-sticky-button',
  templateUrl: './sticky-button.component.html',
  styleUrls: ['./sticky-button.component.scss']
})
export class StickyButtonComponent implements OnInit {
  ngOnInit(): void {
  }
  isAtBottom: boolean = false;

  isHovered: boolean = false; // Track hover state

  onButtonClick() {
    // Your button click logic here
  }

  // Methods to handle mouse events
  onMouseEnter() {
    this.isHovered = true; // Set hover state to true
  }

  onMouseLeave() {
    this.isHovered = false; // Set hover state to false
  }  private isMacOS(): boolean {
    return navigator.platform.toUpperCase().includes('MAC');
  }

  private isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollPosition = window.scrollY + window.innerHeight;
    const totalHeight = document.documentElement.scrollHeight;

    // Adjust threshold based on platform and browser
    const threshold = this.isMacOS() && this.isSafari()
      ? totalHeight - 1 // Safari and MacBook may require tighter margins
      : totalHeight - 10; // Default threshold for other platforms

    this.isAtBottom = scrollPosition >= threshold;

    // Optional debugging
    console.debug('Scroll Position:', scrollPosition);
    console.debug('Total Height:', totalHeight);
    console.debug('Threshold:', threshold);
    console.debug('Is At Bottom:', this.isAtBottom);
  }


}