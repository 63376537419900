import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { LocaleService } from 'src/app/services/locale.service';
import { Locale } from 'src/app/types';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  public locale: Locale | null = null;
  termsContent!: SafeHtml;
  privacyContent!: SafeHtml;
  constructor(private termsService: ApiService, private localeService: LocaleService, private activatedroute: ActivatedRoute, private sanitizer: DomSanitizer) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.localeService.locale$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((locale: Locale) => {
        this.activatedroute.paramMap
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((params) => {
            this.locale = locale;
            this.terms();  // Fetch terms when locale is available
          });
      });
  }

  terms() {
    this.termsService.getTermsAndConditions(this.locale.prefix).subscribe(
      (data) => {
        this.termsContent = this.sanitizer.bypassSecurityTrustHtml(
          data.result?.description || ''
        );
      },
      (error) => console.error('Error fetching terms:', error)
    );

    // Fetch Privacy Policy
    this.termsService.getPrivacyPolicy(this.locale.prefix).subscribe(
      (data) => {
        this.privacyContent = this.sanitizer.bypassSecurityTrustHtml(
          data.result?.description || ''
        );
      },
      (error) => console.error('Error fetching privacy policy:', error)
    );
  }

  

  // private formatTermsContent(content: string): string {
  //   // Remove the first occurrence of "Terms & Conditions" in English or Arabic
  //   const cleanedContent = content.replace(/Terms & Conditions|الشروط والأحكام/i, '').trim();
  
  //   // Use existing format function to add new lines for numbered points and other formatting
  //   return this.formatWithNewLines(cleanedContent);
  // }
  

  // private formatTermsContentprivicy(content: string): string {
  //   // Remove the first occurrence of "Terms & Conditions"
  //   const cleanedContent = content.replace(/Privacy Policy|سياسة الخصوصية/i, '').trim();

  //   // Use existing format function to add new lines for numbered points and other formatting
  //   return this.formatWithNewLines(cleanedContent);
  // }


  // private formatWithNewLines(text: string): string {
  //   return text
  //     .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags if any
  //     .replace(/(\d+\.\s)/g, '<br>$1')   // Add a line break before numbered points (1. )
  //     .replace(/•\s/g, '<br>• ')          // Add a line break before bullet points (• )
  //     .replace(/:\s/g, ':<br>')           // Add a line break after colons
  //     .replace(/\n+/g, '<br>')            // Convert new lines to <br> for HTML rendering
  //     .trim();                            // Trim leading and trailing whitespace
  // }
}
