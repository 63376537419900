import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocaleService } from 'src/app/services/locale.service';
import { Category, Locale, Program, Response } from 'src/app/types';

@Component({
  selector: 'app-competitions',
  templateUrl: './competitions.component.html',
  styleUrls: ['./competitions.component.scss']
})
export class CompetitionsComponent implements OnInit {
	public locale          : Locale    | null = null;
  private _unsubscribeAll: Subject<any>;
  constructor(private localeService   : LocaleService,private activatedroute  : ActivatedRoute) {this._unsubscribeAll = new Subject(); }

  ngOnInit(): void
	{
		this.localeService.locale$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((locale: Locale) => {
			this.activatedroute.paramMap
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((params) => {
				this.locale = locale;
        console.log("localelocale",this.locale.prefix)

			
			});
		});

	}

  ngOnDestroy() : void
  {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }


}
