import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SitemapService } from '../services/sitemap.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sitemap',
  template: ''
})
export class SitemapComponent implements OnInit {
  constructor(private sitemapService: SitemapService) {}

  ngOnInit() {
    this.generateSitemap();
  }

  generateSitemap() {
    const xml = this.sitemapService.generateSitemap();
    this.downloadXml(xml);
  }

  downloadXml(xml: string) {
    const blob = new Blob([xml], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sitemap.xml';
    a.click();
    URL.revokeObjectURL(url);
  }
}