import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorProcessingService } from 'src/app/services/error-processing.service';
import { LocaleService } from 'src/app/services/locale.service';
import { Locale, Response, User } from 'src/app/types';

@Component({
	selector: 'app-password-update',
	templateUrl: './password-update.component.html',
	styleUrls: ['./password-update.component.scss'],
	
})
export class PasswordUpdateComponent implements OnInit, OnDestroy
{
	private _unsubscribeAll: Subject<any>;

	public locale          : Locale | null = null;

	public user            : User    = null;

	public form			   : FormGroup;
	public passwordFieldTypes: { [key: string]: string } = {
		old_password: 'password',
		new_password: 'password',
		new_password_confirmation: 'password',
	  };
	constructor(
		private localeService   : LocaleService,
		private apiService      : ApiService,
		private errorProcessing : ErrorProcessingService,
		private authService     : AuthService,
		private formBuilder		: FormBuilder,
	) 
	{
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
		  old_password: ['', Validators.required],
		  new_password: ['', [Validators.required, Validators.minLength(8)]],
		  new_password_confirmation: ['', Validators.required],
		});
		this.form.disable();
	
		this.localeService.locale$
		  .pipe(takeUntil(this._unsubscribeAll))
		  .subscribe((locale: Locale) => {
			this.locale = locale;
		  });
	
		this.authService.user$
		  .pipe(takeUntil(this._unsubscribeAll))
		  .subscribe((user: User) => {
			this.user = user;
			this.form.enable();
		  });
	  }
	
	  ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	  }
	
	  togglePasswordVisibility(field: string): void {
		this.passwordFieldTypes[field] =
		  this.passwordFieldTypes[field] === 'password' ? 'text' : 'password';
	  }

	
	  updateProfile(): void {

		if (this.form.invalid) {
			return;
		}
	
		this.form.disable();
	
		const { new_password, new_password_confirmation } = this.form.value;

		const payload = {
		  new_password,
		  new_password_confirmation,
		};
	  
		if (new_password !== new_password_confirmation) {
			console.error('Please fill out all required fields correctly.',this.form.value);
			this.form.enable();
		  return;
		}
	
		this.apiService
		  .updateProfilePassword(this.locale?.prefix || '', payload)
		  .pipe(take(1))
		  .subscribe(
			(data: Response) => {
			  const responseResult = this.errorProcessing.auto(data);
			  if (responseResult) {
				this.form.reset();
			  }
			  this.form.enable();
			},
			(error) => {
				console.error('Please fill out all required fields correctly.');
				this.form.enable();
			}
		  );
	  }
	}