<div class="container">
  <!-- Breadcrumb Section -->
  <section class="breadcrumb-page breadcrumb-page pb-120">
    <div class="sub-header">
      <h3>{{ 'Competitions' | translate }}</h3>
      <ol class="breadcrumb">
        <li>
          <a href="javascript:;" [routerLink]="['/', locale.prefix]">
            {{ 'Home' | translate }} /
          </a>
        </li>
        <li>
          <a href="javascript:;" [routerLink]="['/', locale.prefix, 'Competition']">
            &nbsp; {{ 'Competitions' | translate }}
          </a>
        </li>
      </ol>
    </div>
  </section>

  <!-- Competition Header -->
  <div class="card-body pod-body">
    <div class="row p-3 align-items-center">
      <div class="col-12 col-md-6">
        <h5 class="card-header-custom2 Competition-header">"{{ 'Podcast Star' | translate }}"</h5>
        <h5 class="card-header-custom Competition-header">{{ 'Competition' | translate }}</h5>
        <h5 class="card-header-custom Competition-header">{{ 'Discover Your Talent' | translate }}</h5>
        <h5 class="card-header-custom Competition-header">{{ 'and Shine!' | translate }}</h5>

      </div>
      <div class="col-12 col-md-6 text-center">
        <img src="../../../assets/podcasti/images/compition.png" alt="competition" style="height: 300px;" />
      </div>
    </div>
    <!-- Introduction Section -->
    <div class="row mt-5 align-items-center">
      <div class="col-12 col-md-4 text-center">
        <img src="../../../assets/podcasti/images/man-recording1.png" alt="man-recording"
          style="border-radius: 16px;width: 100%;height: 100%;" />
      </div>
      <div class="col-12 col-md-8  ">

        <p class="text-center-align" style="color: #542779; font-size: 25px;">
          {{ 'A Golden Opportunity for University Students in Jordan!' | translate }}
        </p>
        <p class="text-center-align" style="color: #542779; font-size: 25px;">
          {{ 'INTRO' | translate }}
        </p>
        <p class="text-center-align" style="color: #542779; font-size: 25px;">
          {{ 'COMPETITION' | translate }}
        </p>
       
        <p class="text-center-align" style="color: #542779; font-size: 25px;">
          {{ 'OPPORTUNITY' | translate }}
        </p>
        
      </div>
    </div>
    <!-- Competition Details -->
    <div class="row mt-5 text-mession">
      <div>
        <p class="card-header-custom3">
          <strong class="highlighted-word">  {{ 'Competition Details'| translate }}</strong>
        
        </p>
      </div>

      <div class="col-12">
        <ul class="custom-list">
          <li  class="p-list " style="color: #92288F;font-size: 25px;list-style: none;margin-block-end: -15px;">{{ 'Participants :' | translate }}</li>
          <li class="text-color3">{{ 'University students (local and international).' | translate }}</li>
          <li  class="p-list mt-4 " style="color: #92288F;font-size: 25px;list-style: none;margin-block-end: -15px; ">{{ 'The Prize :' | translate }}</li>

          <li class="text-color3">{{ 'A fully-produced podcast series for the winner, featured on our platform and promoted widely.' | translate }}</li>
      
        </ul>
      </div>
    </div>
<!-- Judging Criteria -->
<div class=" row mt-5 " style="padding: 80px;">
  
  <!-- Title Section: "Criteria" and "Judging" on Separate Lines -->
  
 

  <div>
    <p class="card-header-custom3">
      <strong class="highlighted-word">  {{ 'Criteria Judging' | translate }}</strong>
    
    </p>
  </div>
  <!-- Content Section -->
  <div class="row">
    <div class="col-12 col-md-9">
      <ul class="custom-list">
        <li class="text-color3">{{ 'Speak in Arabic (feel free to use your local dialect).' | translate }}</li>
          <li class="text-color3">{{ 'Present a creative, original podcast idea.' | translate }}</li>
          <li class="text-color3">{{ 'Show us your skills in making content exciting and engaging.' | translate }}</li>
          <li class="text-color3">{{ 'Prepare a 3-minute audition that highlights your talent!' | translate }}</li>
      </ul>
    </div>
    <div class="col-12 col-md-3">
      <img src="../../../assets/podcasti/images/mic.png" />
    </div>
  </div>

  <!-- Deadline Section -->
  <!-- <div class="col-12 p-list">
    <p class="text-color3">
      <strong>{{ 'Deadline:' | translate }}</strong> {{ 'December 15, 2024' | translate }}
    </p>
  </div> -->
</div>




    <!-- How to Enter Section -->
    <div class="row mt-5 text-mession">

      <div class="col-12">
        <p class="p-list card-header-custom3">
       
          <strong class="highlighted-word">{{ 'Participation steps' | translate }}</strong>
        
        </p>
      </div>
      <div class="row">
        <div class="col-12">
          <ol>
            <li class="text-color3">
              {{ 'Visit the competition coordinator at your university or fill out the application form here:' |
              translate }}
              <a href="https://forms.gle/rVLjvZGVMgK3eGak9" target="_blank">{{ 'Visit Link' | translate }}</a>
            </li>
            <li class="text-color3">{{ 'We’ll reach out to schedule your audition.' | translate }}</li>
          </ol>
        </div>

      </div>

    </div>
    <div class=" mt-5" style="padding: 80px;">
  
      <!-- Title Section: "Criteria" and "Judging" on Separate Lines -->
      
      <div class="row">
        <p class="card-header-custom3">

          
          <strong class="highlighted-word">{{ 'Application Criteria' | translate }}</strong>
      
        </p>
      </div>
      <!-- Content Section -->
      <div class="row">
        <div class="col-12 ">
          <ul class="custom-list">
            <li class="text-color3">{{ 'Creativity and originality of your podcast idea.' | translate }}</li>
            <li class="text-color3">{{ 'On-camera presentation and interview skills.' | translate }}</li>
            <li class="text-color3">{{ 'Your ability to engage the audience and keep them hooked!' | translate }}</li>
    
          
          </ul>
        </div>
      
      </div>
    
      <!-- Deadline Section -->
  
    </div>
    
    <!-- Why Should You Participate Section -->
    <div class="row text-mession2 " style="margin-top: 100px;">
      <div class="col-12 col-md-6 image-fit " >
      



        <img 
        *ngIf="locale.prefix === 'ar'; else defaultImage" 
        src="../../../assets/podcasti/images/paticiption.png" 
        alt="participation in Arabic"
        width="1300px"
      />
      <ng-template #defaultImage>
        <img 
          src="../../../assets/podcasti/images/paticiptionen.png" 
          alt="participation"
          width="1300px"

        />
      </ng-template>
      </div>
      <div class="col-12 col-md-6 p-list">
        <h2 class="Competition">{{ 'Why Should You Participate?' | translate }}</h2>

        <ol class="list-top">
          <li class="text-color3">{{ 'Represent your university on a platform that reaches a global audience.' |
            translate }}</li>
          <li class="text-color3">{{ 'Get your creative ideas out there on a popular Arabic platform with followers from
            all over the world.' | translate }}</li>
          <li class="text-color3">{{ 'Enhance your presentation and production skills – and get noticed by industry
            experts!' | translate }}</li>
        </ol>
      </div>
   
    </div>
    <!-- Contact Section -->
    <div class="row mt-5 end-section-conp" style="padding: 80px;">
      <div class="col-12 ">
        <div class="p-list">
          <p class="text-color3">
            <strong>{{ 'Deadline:' | translate }}</strong> {{ '30th December 2024' | translate }}
          </p>
        </div>
        <h4 class="text-color3">{{ 'Got Questions?' | translate }}</h4>
        <p class="mt-3">
          <i class="fas fa-envelope text-primary"></i>
          <strong class="text-color3"> {{ 'Email' | translate }} :</strong>
          <a href="mailto:rana.s@podqasti.com" class="text-decoration-none text-color3">rana.s@podqasti.com</a>
        </p>
        <p>
          <i class="fas fa-phone text-success"></i>
          <strong class="text-color3"> {{ 'Phone :' | translate }}</strong>
          <a href="tel:0792666069" class="text-decoration-none text-color3">0792666069</a>
        </p>
        
        <p class="mt-4 text-color3">
          {{ 'Don’t miss out on this amazing opportunity! Apply now and become the next Podcast Star!' | translate }}
        </p>

      </div>
    </div>
  </div>
</div>