import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {
  constructor(private router: Router) {}

  generateSitemap(): string {
    const routes = this.router.config;
    const urls = this.getRoutes(routes);
    const xml = this.buildXml(urls);
    return xml;
  }

  private getRoutes(routes: any[], parentPath = ''): string[] {
    let urls: string[] = [];
    for (const route of routes) {
      const path = parentPath + (route.path ? `/${route.path}` : '');
      urls.push(`${path}`);
      if (route.children) {
        urls = [...urls, ...this.getRoutes(route.children, path)];
      }
    }
    return urls;
  }

  private buildXml(urls: string[]): string {
    const urlList = urls
      .map(url => `
        <url>
          <loc>${`https://yourdomain.com${url}`}</loc>
        </url>
      `)
      .join('');

    return `
      <?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap-image/1.1">
        ${urlList}
      </urlset>
    `.trim();
  }
}