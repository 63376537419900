<ng-container  *ngIf="episode">
  <div class="col-12 " >
    <!-- Audio Player -->
    <audio controls controlsList="nodownload" class="mt-3 episode-controls">
      <source [src]="episode.link" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
  
    <!-- <div class="row align-items-center">
      <div class="col-9 col-md-9">
        <div id="waveform" class="waveform"></div>
        <div *ngIf="isLoading" class="text-center">
          <p>Loading waveform...</p>
          
        </div>
      </div>
      <div class="col-1  col-md-1 text-center">
        <button (click)="playPause()" class="play-button">
            <i class="fas" [ngClass]="waveSurfer?.isPlaying() ? 'fa-pause' : 'fa-play'"></i>
        </button>
      </div>
    </div> -->
  </div>
</ng-container>



