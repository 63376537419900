<div appDynamicMeta  *ngIf="category"
     [pageTitle]="locale.prefix === 'ar' 
        ? ' بودكاست عربية في فئة ' + title + ' على بودكاستي' 
        : title + ' Arabic Podcasts on Podqasti'"
     [pageDescription]="locale.prefix === 'ar' 
        ? 'انغمس في عالم البودكاست العربية في فئة ' + title + ' على بودكاستي. اكتشف برامج آسرة وقصصًا ملهمة تلبي اهتماماتك.' 
        : 'Dive into a world of ' + title+ ' Arabic podcasts on Podqasti. Discover captivating programs and inspiring stories that cater to your interests.'">
</div>








<div class="container">
    <section class="breadcrumb-page breadcrumb-page pb-120">
        <div class="sub-header">
            <h3>{{ 'Categories' | translate }}</h3>
            <ol class="breadcrumb">
                <li>
                    <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
                </li>
                <li>
                    <a href="javascript:;" [routerLink]="['/', locale.prefix, 'categories']">&nbsp; {{'Categories' |
                        translate }} /</a>
                </li>
                <li *ngIf="category">
          <a href="javascript:;">&nbsp; {{title }}</a> 
                </li>
            </ol>
        </div>
    </section>



    <section class="custom-section mt-5" *ngIf="category">
        <div class="title-wrapper">
            <h2 class="main-title">
                {{ 'Programs' | translate }}   
            </h2>
            <!-- Optional "See More" Link -->
            <!-- <a class="see-more" [routerLink]="['/', locale.prefix, 'Most-Popular']">{{ 'See More' | translate }}</a> -->
        </div>
     
        

            <div >
                <div class="row">
                  <div class="col-12 col-md-3"*ngFor="let program of category">
                    <app-program-podcaster-card [program]="program" [locale]="locale"
                    [footer]="false"></app-program-podcaster-card>
                  </div>
                </div>
              </div>

    </section>

    <section class="custom-section mt-5" *ngIf="categoryData">
        <div class="title-wrapper">
            <h2 class="main-title">
                {{'Episodes' | translate }}
            </h2>
            <!-- Optional "See More" Link -->
            <!-- <a class="see-more" [routerLink]="['/', locale.prefix, 'Most-Popular']">{{ 'See More' | translate }}</a> -->
        </div>
      
         
      
            <div >
                <div class="row">
                  <div class="col-12 col-md-3"*ngFor="let item of categoryData">
                    <app-vertical-episode [item]="item" [locale]="locale"
                    ></app-vertical-episode>
                  </div>
                </div>
              </div>


    </section>



</div>