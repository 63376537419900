import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'app-audio-waveform',
  templateUrl: './audio-waveform.component.html',
  styleUrls: ['./audio-waveform.component.scss']
})
export class AudioWaveformComponent implements AfterViewInit, OnDestroy {
  @Input() episode: any; // Assuming episode has a `link` property
  @ViewChild('waveform', { static: false }) waveformRef: ElementRef; // Access the waveform container
  private waveSurfer: WaveSurfer;
  isLoading = false; // Loading state for the waveform

  ngAfterViewInit(): void {
    if (this.episode && this.episode.link) {
      this.initWaveform();
    } else {
    }
  }
  

  initWaveform(): void {
    if (this.waveformRef && this.episode.link) {
     
      this.waveSurfer = WaveSurfer.create({
        container: this.waveformRef.nativeElement, // Reference to DOM element
        waveColor: 'rgb(200, 0, 200)', // Light gray wave color
        progressColor: 'rgb(100, 0, 100)', // Progress color for played portion
        height: 200, // Height of waveform
        responsive: true, // Ensure responsiveness on window resize
        barWidth: 4, // Width of each bar
        barGap: 1, // Gap between bars
        barRadius: 2, // Round bars for smoother visual
        cursorColor: '#92288F', // Cursor (playhead) color
      });

      // Load the audio file into WaveSurfer
      this.waveSurfer.load(this.episode.link);

      // When the waveform is ready, remove the loading indicator
      this.waveSurfer.on('ready', () => {
        this.isLoading = false;
        this.waveSurfer.play(); // Optional: Start playing automatically when ready
      });

      // Handle errors if the audio cannot be loaded
      this.waveSurfer.on('error', (e) => {
        this.isLoading = false; // Stop showing the loader in case of an error
      });
    } else {
    }
  }

  // Cleanup to prevent memory leaks
  ngOnDestroy(): void {
    if (this.waveSurfer) {
      this.waveSurfer.destroy();
    }
  }

  // Optional: Play/pause functionality linked to a button
  playPause(): void {
    if (this.waveSurfer) {
      this.waveSurfer.isPlaying() ? this.waveSurfer.pause() : this.waveSurfer.play();
    }
  }
}
