
<div appDynamicMeta 
     [pageTitle]="locale.prefix === 'ar' ? 'أفضل منشئي البودكاست العرب على بودكاستي: اكتشف أصواتًا جديدة' : 'Top Arabic Podcasters on Podqasti: Discover New Voices'"
     [pageDescription]="locale.prefix === 'ar' ? ' تعرف على منشئي البودكاست العرب الموهوبين على بودكاستي. اكتشف أصواتًا جديدة وقصصًا آسرة من جميع أنحاء العالم.': 'Meet the talented Arabic podcasters on Podqasti. Discover new voices and captivating stories from around the globe.'">
</div>



<div class="container"><section class="breadcrumb-page">
    <div class="sub-header">
        <h3>{{ 'Podcasters' | translate }}</h3>
        <ol class="breadcrumb">
            <li>
                <a href="javascript:;" [routerLink]="['/']"> {{ 'Home' | translate }} / </a>
            </li>
            <li>
                <a href="javascript:;">&nbsp; {{'Podcasters' | translate}}</a>
            </li>
        </ol>
    </div>
</section>

<section class="custom-section" *ngIf="podcastersData">
    <div class="container">
        <div class="row">
            <div class=" col-md-6 col-lg-3 col-12" *ngFor="let item of podcastersData">
                <a class="link" ng-reflect-router-link="/,en,podcaster,{{item.id}}" [routerLink]="['/', locale?.prefix || 'en', 'podcaster', item.id]" >
                    <div class="item card mt-3 mr-1 ml-1 item-card">
                        <div href="#" class="image-link">
                            <div class="image-container" >
                                <img *ngIf="!item.imageLoaded" class="loader-img"
                                    src="../../../assets/podcasti/images/logooo.png" alt="Loading...">
                                <img *ngIf="item.image" class="card-img-top" [src]="baseImageUrl + item.image"
                                    alt="{{ item.title }}" (load)="onImageLoad(item)">
                            </div>
                            <div class="color-overlay">
                                <div href="category-nav" class="card-icon item-nav"><i aria-hidden="true"
                                        class="fa fa-ellipsis-v"></i>
                                    <div>
                                        <ul class="dropdown-menu custom-dropdown-menu"><a href="javascript:;"
                                                class="dropdown-item"><i class="fa-solid fa-star"></i><span>Add To
                                                    Favorite</span></a></ul>
                                    </div>
                                </div><a class="play-icon" ng-reflect-router-link="/,en,podcaster,{{item.id}}"
                                    href="#/en/podcaster/{{item.id}}"><i class="fas fa-play"></i></a><a
                                    href="javascript:;" class="card-icon"><i aria-hidden="true" class="fa-solid fa-star"
                                        ng-reflect-ng-class="[object Object]"></i></a>
                            </div>
                        </div>
                        <div class="card-body pod-body mt-1">
                            <h5 class="card-title"> {{ item.title }}</h5>
                            <p class="p-info">{{stripHtmlTags(item.description) }}</p><!--bindings={
                "ng-reflect-ng-if": "true"
              }-->
                            <!-- <span>Podcast by: <a class="p"> {{ item.title }}</a></span> -->
                            <!--bindings={
                "ng-reflect-ng-if": "[object Object]"
              }-->
                            <ul class="list-inline m-0 card-ul">
                                <li class="program-icons"><i class="far fa-heart"></i>{{ item.favoredBy}} <span
                                        class="comments"></span></li>
                            </ul><!--bindings={
                "ng-reflect-ng-if": "true"
              }-->
                        </div>
                    </div>
                </a>

            </div>
        </div>
    </div>

    <div class="btn-programs m-5">
        <button (click)="nextPage()"> {{'See More' | translate}}</button>

    </div>
</section></div>
