<!-- <div class="sticky-button">






  
    <a href="https://qr.podqasti.com" target="_blank">
        <button class="icon icon-default">
          <img 
            src="../../assets/podcasti/images/sticky button 2.png" 
            alt="Plus" 
            class="download-img"
          />
        </button>
      </a>
      <a href="https://qr.podqasti.com" target="_blank">
    <button class="icon icon-hover" (click)="onButtonClick()">
      <img 
        src="../../assets/podcasti/images/sticky button 3.png" 
        alt="Check" 
      
      
      />
    </button>
    </a>
  </div>
   -->










   <div class="download-banner" [class.stick-to-top]="isAtBottom">
    <div class="container">
      <div class="text">  
        {{'install our app' | translate}}
      </div>
      <div class="buttons">
        <a href="https://qr.podqasti.com">
          <img src="../../assets/podcasti/images/stikey-app.png" alt="Get it on Google Play">
        </a>
        <a href="https://qr.podqasti.com">
          <img src="../../assets/podcasti/images/stikry-google.png" alt="Download on the App Store">
        </a>
      </div>
      
    </div>
  </div>