<!-- <div class="mt-1 card-parent" [ngClass]="{ 'card-width': applyClass }">
  <div class="item card mt-3 item-card" >
    <div class="image-link">
      <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]">
        <img class="card-img-top slider-img" [src]="item.image" alt="" />
      </a>
    </div>

<div class="card-body pod-body d-flex flex-column">
  <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]">
    <h5 class="card-title text-center my-3">{{ item.title }}</h5>
  </a>

  <span class="my-3 text-center flex-grow-1">
    <a [routerLink]="['/', locale.prefix, 'program', item.program_id]" class="text-muted one-line-details">{{ item.program_title }}</a>
    <a class="pt-3 text-muted" style="display: block; min-height: 40px;">{{ item.duration }}</a>
  </span>

  <div >
    <div class="" >
      < <app-vertical-eposide-player
        [locale]="locale"
        [episode]="item"
        class="one-line-details mt-auto"
        (onStateChanged)="onStateChanged($event)"
        (onManuelStateChanged)="onManuelStateChanged($event)">
      </app-vertical-eposide-player> 

      <app-audio-waveform 
      [episode]="item"></app-audio-waveform>

    </div>
  </div>
</div>

  </div>
</div> -->




<div class="mt-1 card-parent" [ngClass]="{ 'card-width': applyClass }">

  <div 
  class="card" 
  style="width: 15rem" 
  [ngStyle]="{ 'min-height': appeartext ? '495px' : '100px' }">
 
  
  <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]">
    <div *ngIf="!imageLoaded">
      <img src="../../../assets/podcasti/images/logooo.png"  alt="Loading..." />
    </div>
    <img *ngIf="item.image"  class=" card-img-top " [src]="item.image" alt=""  (load)="onImageLoad()" 
    (error)="imageLoaded = true"  />
  </a>
  <div class="card-body">
    <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]">
      <h5 class="card-title text-center">{{ item.title }}</h5>
    </a>
  
    <!-- Program Title and Duration -->
    <span class="my-3 text-center flex-grow-1" *ngIf="appeartext">
      <a [routerLink]="['/', locale.prefix, 'program', item.program_id]" class="text-muted one-line-details">{{ item.program_title }}</a>
      <a class="pt-3 text-muted" style="display: block; min-height: 40px;">{{ item.duration }}</a>
    </span>
    <app-audio-waveform  *ngIf="appeartext"
    [episode]="item"></app-audio-waveform>
  </div>
</div>
</div>



