<!-- <div class="item card mb-3 custom-card-r">
    <a [routerLink]="['/', locale.prefix, 'category', category.category_id]" class="image-link">
        <img class="card-img-top hover-shadow" [src]="category.image" alt="">
    </a>
</div> -->
<div class="item  mb-3 custom-card">
    <a [routerLink]="['/', locale.prefix, 'category', category.category_id]" class="image-link category-image">
        <!-- <h4>{{'App Name' | translate}}</h4> -->
        <!-- <h3>{{ category.title }}</h3> -->
        <div class="category-container">
            <div *ngIf="isLoading" class="loading-spinner">
                <img src="../../../assets/podcasti/images/logooo.png" alt="Loading..." class="loading-spinner-image category-image">
              </div>
            
              <!-- Display category image if available and handle error fallback -->
              <img *ngIf="imageUrl" [src]="imageUrl" alt="Category Image" class="category-image" (load)="onImageLoad()" (error)="onImageError($event)">
            
              <!-- Display default image if category image is not available and loading is complete -->
              <img *ngIf="!imageUrl && !isLoading" src="../../../assets/podcasti/images/logooo.png" alt="Default Image" class="category-image">
      

            <h4 class="category-text">{{category.title | translate}}</h4>
          </div>
    </a>
</div>